// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import getCore from 'cvat-core-wrapper';

const core = getCore();

export enum KoreActionTypes {
    KORE_URLS = 'KORE_URLS',
    KORE_URLS_SUCCESS = 'KORE_URLS_SUCCESS',
    KORE_URLS_FAILED = 'KORE_URLS_FAILED',
}

const koreActions = {
    koreUrls: () => createAction(KoreActionTypes.KORE_URLS),
    koreUrlsSuccess: (koreUrls: Array<string>) => createAction(KoreActionTypes.KORE_URLS_SUCCESS, { koreUrls }),
    koreUrlsFailed: (error: any) => createAction(KoreActionTypes.KORE_URLS_FAILED),
};

export type KoreAction = ActionUnion<typeof koreActions>;



export const getUrlsAsync = (): ThunkAction => async (dispatch) => {
    dispatch(koreActions.koreUrls());

    try {
        const res = await core.server.koreUrls();

        dispatch(koreActions.koreUrlsSuccess(res.urls));
    } catch (error) {
        dispatch(koreActions.koreUrlsFailed(error));
    }
};
