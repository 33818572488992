// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import getCore from 'cvat-core-wrapper';
import { CanvasVersion } from 'cvat-canvas-wrapper';
import { KoreAction, KoreActionTypes } from 'actions/kore-actions';
import { KoreState } from './interfaces';
import pjson from '../../package.json';

const defaultState: KoreState = {
    fetching: false,
    initialized: false,
    urls: [],
};

export default function (
    state: KoreState = defaultState,
    action: KoreAction,
): KoreState {
    switch (action.type) {
        case KoreActionTypes.KORE_URLS:
            return {
                ...state,
                fetching: true,
            };
        case KoreActionTypes.KORE_URLS_SUCCESS:
            return {
                ...state,
                fetching: false,
                initialized: true,
                urls: action.payload.koreUrls,
            };
        case KoreActionTypes.KORE_URLS_FAILED:
            return {
                ...state,
                fetching: false,
                initialized: true,
            };
        default:
            return state;
    }
}
