// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Result } from 'antd';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Spin from 'antd/lib/spin';

interface AuthProps {
    koreToken: string;
    forwardUrl: boolean;
    koreUrls: Array<string>;
    tokenFetching: boolean;
    tokenInitialized: boolean;
    checkToken: (token: string) => void;
}

type Props = AuthProps & RouteComponentProps<{
    tid: string;
    jid: string;
}>;

class AuthenticationComponent extends React.PureComponent<Props> {


    public componentDidMount(): void {
        const { koreUrls, forwardUrl, tokenFetching, tokenInitialized, koreToken } = this.props;
        const { match: { params } } = this.props;
        const { history } = this.props;

        // If we have a valid token verified by request then forward onto annotation page
        if (forwardUrl === true && koreToken != '' && tokenInitialized === true) {
            window.onmessage = null;
            history.push( `/tasks/${params.tid}/jobs/${params.jid}`);
            return;
        }

        // Token failed authentication request so redirect user to not authorized page
        if (forwardUrl === true && tokenInitialized === true  && koreToken != '') {
            history.push('/forbidden');
            return;
        }

        if (tokenFetching == false) {
            this.setupAuthenticationListner();
        }

    }

    public componentDidUpdate(): void {
        const { tokenFetching, tokenInitialized} = this.props;

        if (tokenFetching == false) {
            this.setupAuthenticationListner();
        }

    }

    private setupAuthenticationListner = (): void => {
        const { history } = this.props;
        const {  koreUrls, forwardUrl, tokenFetching, tokenInitialized } = this.props;
        const { checkToken } = this.props;

        if (koreUrls.length > 0  && !tokenFetching) {
            let currentUrl = document.referrer;
            let arr = currentUrl.split("/");
            if (arr.length > 1) {
                var parentDomain = arr[0] + "//" + arr[2];
                let url = koreUrls.find((url: string) => url === parentDomain);
                if (window.parent && url ) {
                    window.onmessage = async (e: MessageEvent) => {
                        // If url from origin dosn't match valid kore urls treat request as unsafe.
                        const validUrl = koreUrls.find((url: string) => url === e.origin);
                        if (!validUrl) {
                            window.onmessage = null;
                            history.push('/forbidden');
                            return;
                        }

                        // Check the token against the server before assigning
                        if (e.data !== '' && tokenFetching === false) {
                            checkToken(e.data);
                        }
                    };
                    if (!tokenInitialized) {
                        window.parent.postMessage('AuthenticatorLoaded', url);
                    }

                }
            }
        }
    };


    public render(): JSX.Element {
        const { forwardUrl } = this.props;
        if (forwardUrl) {
            return (

                <div id='CVATAuthenticator'>
                    <Result
                    icon={<Spin size='large' className='cvat-spinner' />}
                    title='Checking permissions...'
                    />
                </div>
            );
        }
        return (<div style={{display: "none"}}></div>)
    }
}

export default withRouter(AuthenticationComponent);
