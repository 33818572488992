// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import AuthenticationPageComponent from 'components/authentication-page/authenticator';
import { CombinedState } from 'reducers/interfaces';
import { checkTokenAsync } from 'actions/auth-actions';

interface StateToProps {
    koreToken: string;
    koreUrls: Array<string>;
    tokenFetching: boolean;
    tokenInitialized: boolean
}


function mapStateToProps(state: CombinedState): StateToProps {
    return {
        koreToken: state.auth.koreToken,
        koreUrls: state.kore.urls,
        tokenInitialized: state.auth.tokenInitialized,
        tokenFetching: state.auth.tokenFetching,
    };
}

interface DispatchToProps {
    checkToken(token: string): void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        checkToken(token): void {
            dispatch(checkTokenAsync(token));
        }
    };
}


function AuthenticationPageContainer(props: StateToProps, dispatch: DispatchToProps): JSX.Element {
    return (
        <AuthenticationPageComponent {...props} {...dispatch} forwardUrl={ true } />
    );
}




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticationPageContainer);
