// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Result, Row } from 'antd';

export default function ForbiddenPageContainer(): JSX.Element {
    return (
        <Row type='flex' justify='center' align='middle' style={{ height: '100%' }}>
            <Result
                status='403'
                title='403'
                subTitle='Sorry, you are not authorized to access this page.'
            />
        </Row>
    );
}
