// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';
import Result from 'antd/lib/result';

import { Workspace } from 'reducers/interfaces';
import AnnotationTopBarContainer from 'containers/annotation-kore-page/top-bar/top-bar';
import StatisticsModalContainer from 'containers/annotation-kore-page/top-bar/statistics-modal';
import StandardWorkspaceComponent from './standard-workspace/standard-workspace';
import AttributeAnnotationWorkspace from './attribute-annotation-workspace/attribute-annotation-workspace';
import TagAnnotationWorkspace from './tag-annotation-workspace/tag-annotation-workspace';
import AuthenticationComponent from 'components/authentication-page/authenticator';

interface Props {
    job: any | null | undefined;
    fetching: boolean;
    koreToken: string;
    koreUrls: Array<string>;
    tokenFetching: boolean;
    tokenInitialized: boolean
    getJob(): void;
    saveLogs(): void;
    checkToken: (token: string) => void;
    workspace: Workspace;
    user: any;
}

export default function AnnotationPageComponent(props: Props): JSX.Element {
    const {
        job,
        fetching,
        getJob,
        saveLogs,
        workspace,
        koreUrls,
        tokenFetching,
        tokenInitialized,
        koreToken,
        checkToken,
        user
    } = props;

    useEffect(() => {
        saveLogs();
        const root = window.document.getElementById('root');


        if (root) {
            root.classList.add("background-kore");
            root.style.minHeight = '768px';
            root.style.padding = '15px'
        }

        return () => {
            saveLogs();
            if (root) {
                root.style.minHeight = '';
            }
        };
    }, []);

    if (job === null) {
        if (!fetching) {
            getJob();
        }

        return ( <Result
            icon={<Spin size='large' className='cvat-spinner' />}
            title='Loading Images...'
            />);
    }

    if (typeof (job) === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title='Sorry, but this job was not found'
                subTitle='Please, be sure information you tried to get exist and you have access'
            />
        );
    }

    return (
        <Layout className='cvat-annotation-page'>

            { workspace === Workspace.STANDARD && (
                <Layout.Content>
                    <StandardWorkspaceComponent />
                </Layout.Content>
            )}
            { workspace === Workspace.ATTRIBUTE_ANNOTATION && (
                <Layout.Content>
                    <AttributeAnnotationWorkspace />
                </Layout.Content>
            )}
            { workspace === Workspace.TAG_ANNOTATION && (
                <Layout.Content>
                    <TagAnnotationWorkspace />
                </Layout.Content>
            )}
            <StatisticsModalContainer />

            <Layout.Footer className='cvat-annotation-footer'>
                <AnnotationTopBarContainer />
            </Layout.Footer>

            <AuthenticationComponent checkToken={checkToken} koreToken={koreToken} forwardUrl={ false } koreUrls={koreUrls} tokenFetching={tokenFetching} tokenInitialized={tokenInitialized} />
        </Layout>
    );
}
